import { useEffect, useState } from "react";

/**
 * Debounce Hook
 *
 * FROM: https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
 * @param value any value
 * @param delay number
 */

export const useDebounce = <T>(value: T, delay = 1000): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};
